/* --------------------------------------------------------------
    Import CSS Dependencies
-------------------------------------------------------------- */
@import 'colors';
@import 'icons';
@import 'fonts';
@import 'default';
@import 'nav';
@import 'buttons';
/* --------------------------------------------------------------
    Start Project CSS
-------------------------------------------------------------- */
html {
  position: relative;
  height: 100%;
  background-color: #fff;
  // overflow-y: scroll;
  // -webkit-font-smoothing: antialiased;
  // -moz-osx-font-smoothing: grayscale;
}
body {
  height: 100%;
  font-family: var(--font-family);
  text-rendering: optimizeLegibility;
  color: #555555;
}
.wrap {
  padding: 30px 0;
}
.container-fluid.but-fixed {
  max-width: 1356px;
}
.footer {
  padding-top: 20px;
  padding-bottom: 15px;
  width: 100%;
  background-color: #f5f5f5;
  color: #999999;
  border-top: 1px solid #e4e4e4;
  font-size: 0.9rem;
  p {
    margin: 0 0 5px 0;
  }
  p.trustmark {
    display: none;
    margin: 0;
  }
  a {
    color: #999999;
  }
  .social a {
    border: 0;
  }
  img {
    display: inline-block;
  }
}

/* --------- asc styles --------- */
#header {
  background: rgba(255, 255, 255, 0.8);
  float: none;
  a.logo {
    float: left;
    margin-top: 12px;
    margin-bottom: 12px;
    border: 0;
    width: 55%;
    max-width: 400px;
    img {
      display: inline-block;
      margin-right: 5px;
      vertical-align: middle;
      width: 100%;
      height: auto;
    }
    span.big,
    span.small {
      display: inline;
      font-size: 1.5em;
      font-weight: 500;
      color: #555;
      line-height: 1em;
      vertical-align: middle;
    }
    span.big {
      font-weight: 600;
      font-family: var(--font-family);
    }
    span.small {
      display: none;
      margin-top: 0;
      font-size: 16px;
      font-family: var(--font-family);
      font-weight: normal;
    }
  }
  #support {
    display: none;
    float: right;
    margin: 0 0 0 0;
    width: auto;
    p {
      float: right;
      margin: 2px 12px 0 0;
      strong {
        font-size: 16px;
      }
    }
    a.login {
      float: right;
      margin: 0 0 0 0;
      padding: 0 12px 2px 12px;
      font-weight: normal;
      color: #fff;
      background: $theme-primary;
      text-decoration: none;
      border-radius: 0 0 4px 4px;
      border: 0;
      &:before {
        display: inline-block;
        margin-right: 5px;
        width: inherit;
        height: inherit;
        content: '\e828';
        color: inherit;
        font-family: 'icons';
        font-weight: normal;
        text-align: center;
        font-size: 105%;
        vertical-align: top;
      }
      &:hover {
        background: lighten($theme-primary, 7.5%);
      }
    }
    span.phone {
      display: none;
      padding-right: 8px;
      border-right: 1px dotted #333;
    }
    a.livechat {
      float: right;
      margin: 0 0 0 10px;
      font-weight: normal;
      color: #555;
      &:before {
        display: inline-block;
        margin-right: 5px;
        width: inherit;
        height: inherit;
        content: '\e830';
        color: inherit;
        font-family: 'icons';
        font-weight: normal;
        text-align: center;
        font-size: 105%;
        vertical-align: top;
      }
    }
    span.cart-link {
      float: right;
      padding-left: 10px;
      border-left: 1px dotted #333;
    }
    a.cart {
      &:before {
        display: inline-block;
        margin-right: 5px;
        width: inherit;
        height: inherit;
        content: '\e8cc';
        color: inherit;
        font-family: 'Material Icons Outlined';
        font-weight: 400;
        text-align: center;
        font-size: 105%;
        vertical-align: top;
      }
    }
  }
  #search {
    float: right;
    clear: right;
    margin: 12px 0;
    input[type='search'] {
      width: 130px;
      transition: all 0.5s cubic-bezier(0, 0.7, 0, 1);
      &:focus {
        width: 250px;
        background-color: #fff;
      }
      &::placeholder {
        color: #999 !important;
      }
    }
  }
}
input.addsearch {
  background: #f2f2f2
    url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 24 24'%3E%3Cg fill='none' stroke='gray' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2'%3E%3Cpath d='M10 3a7 7 0 107 7 7 7 0 00-7-7zM21 21l-6-6' vector-effect='non-scaling-stroke'%3E%3C/path%3E%3C/g%3E%3C/svg%3E")
    no-repeat 9px center;
  padding-left: 35px;
}
#phones {
  padding: 2px 10px;
  background: $theme-primary;
  color: #fff;
  font-weight: 600;
  font-size: 0.9em;
}
#nav {
  form {
    padding: 9px 20px;
    label {
      color: #fff;
    }
    input {
      margin-bottom: 5px;
      border: 0;
    }
  }
}
.bg-primary {
  background-color: $theme-primary !important;
}
.bg-orange {
  background: #f63;
}
.bg-light-blue {
  background: #0872b9 !important;
}
.bg-gray {
  background: #e5e5e5 !important;
}
.off-center {
  position: relative;
  top: -1px;
}
#banner-wrap {
  position: relative;
  h1,
  h2 {
    font-weight: 700 !important;
    text-shadow:
      2px 2px 0 rgba(0, 0, 0, 0.8),
      0 0 7px rgba(0, 0, 0, 1);
  }
  h1,
  h2,
  h3,
  .h1,
  .h2,
  .h3 {
    color: #fff;
    text-shadow:
      2px 2px 0 rgba(0, 0, 0, 0.8),
      0 0 7px rgba(0, 0, 0, 1);
  }
  .details {
    color: #fff;
    font-size: 1.2rem;
    text-shadow: 2px 1.5px 0 rgba(0, 0, 0, 0.8);
    ul {
      font-size: 1.1rem;
      li:before {
        color: #09f;
      }
      a {
        color: inherit;
        border-bottom: dotted 1px;
      }
    }
  }
  .details-contained {
    max-width: 610px;
  }
  .dropdown-list {
    display: inline-block;
    margin: 0;
    vertical-align: middle;
    &.short {
      max-width: 200px;
    }
  }
  .filler {
    background-color: rgba(255, 255, 255, 0.85);
    h1,
    h2,
    h3 {
      text-shadow: 0 0 0;
    }
  }
  .price.circle {
    background-color: transparent;
    padding-top: 0;
    width: auto;
    height: auto;
    &.offer {
      .text {
        display: block;
        font-size: 14px;
        line-height: 1;
      }
    }
    span.subtext,
    span.slashprice {
      color: #efefef;
      margin-right: 0;
    }
    span.subtext {
      font-size: 14px;
    }
    span.slashprice {
      font-size: 24px;
      margin: 0px;
      &:after {
        background-color: #efefef;
      }
    }
  }
  img.iacet {
    max-width: 150px;
    height: auto;
    position: absolute;
    bottom: 40px;
    right: 20px;
  }
  .cta-btn,
  .cta-dropdown {
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25);
  }
  .special-offer {
    background: #fff;
    width: max-content;
    color: #555;
    text-shadow: none;
    border-radius: 15px;
    border: 2px solid #f63;
    box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.65);
    overflow: hidden;
  }
  .transparent {
    background: none;
    border: none;
    margin: 0 auto;
  }
  .action.action-btns {
    .cta-btn {
      width: 220px;
      &.large {
        min-width: 300px;
      }
    }
  }
  &.faded-bg {
    * {
      z-index: 1;
    }
    &:before {
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      z-index: 0;
      opacity: 0.6;
      background-color: #000;
    }
  }
  &.abc {
    background: #000 url(../images/banners/abc-bg-sm.jpg) no-repeat 50% 50% / cover;
  }
  &.aerial-scissor-lift {
    background: #000 url(../images/banners/aerial-scissor-lift-bg-sm.jpg) no-repeat 50% 50% / cover;
  }
  &.brand-awareness,
  &.promo-codes {
    background: #000 url(../images/banners/brand-awareness-bg-sm.jpg) no-repeat 50% 50% / cover;
  }
  &.business {
    background: #000 url(../images/banners/business-bg-sm.jpg) no-repeat 50% 50% / cover;
    &.lp {
      background: #000 url(../images/banners/business-bg-lp-sm.jpg) no-repeat 50% 0 / cover;
    }
  }
  &.calosha {
    background: #000 url(../images/banners/calosha-bg-sm.jpg) no-repeat 50% 50% / cover;
  }
  &.calosha-10-con {
    background: #000 url(../images/banners/calosha-10-con-bg-sm.jpg) no-repeat 50% 50% / cover;
  }
  &.calosha-30-con {
    background: #000 url(../images/banners/calosha-30-con-bg-sm.jpg) no-repeat 50% 50% / cover;
  }
  &.calosha-10-gen {
    background: #000 url(../images/banners/calosha-10-gen-bg-sm.jpg) no-repeat 50% 50% / cover;
  }
  &.calosha-30-gen {
    background: #000 url(../images/banners/calosha-30-gen-bg-sm.jpg) no-repeat 50% 50% / cover;
  }
  &.concrete-masonry {
    background: #000 url(../images/banners/concrete-and-masonry-bg-sm.jpg) no-repeat 50% 50% / cover;
  }
  &.construction-social {
    background: #000 url(../images/banners/oec2-bg-sm.jpg) no-repeat 50% 50% / cover;
  }
  &.current-workers {
    background: #000 url(../images/banners/current-workers-bg-sm.jpg) no-repeat 50% 10% / cover;
  }
  &.dot {
    background: #000 url(../images/banners/dot-bg-sm.jpg) no-repeat 50% 50% / cover;
  }
  &.drug-alcohol {
    background: #000 url(../images/banners/da-bg-sm.jpg) no-repeat 50% 50% / cover;
  }
  &.electrocution-prevention {
    background: #000 url(../images/banners/electrocution-prevention-bg-sm.jpg) no-repeat 50% 50% / cover;
  }
  &.excavation {
    background: #000 url(../images/banners/excavations-bg-sm.jpg) no-repeat 50% 50% / cover;
  }
  &.fall-prevention {
    background: #000 url(../images/banners/fall-prevention-bg-sm.jpg) no-repeat 50% 50% / cover;
  }
  &.fire-protection {
    background: #000 url(../images/banners/fire-prevention-bg-sm.jpg) no-repeat 50% 50% / cover;
  }
  &.flagger {
    background: #000 url(../images/banners/flagger-bg-sm.jpg) no-repeat 50% 50% / cover;
  }
  &.forklift {
    background: #000 url(../images/banners/forklift-bg-sm.jpg) no-repeat 50% 10% / cover;
  }
  &.forklift-2 {
    background: #000 url(../images/banners/forklift-2-bg-sm.jpg) no-repeat 50% 10% / cover;
  }
  &.general-social {
    background: #000 url(../images/banners/oec3-bg-sm.jpg) no-repeat 50% 50% / cover;
  }
  &.hazwoper {
    background: #000 url(../images/banners/hazwoper-bg-sm.jpg) no-repeat 50% 50% / cover;
  }
  &.hazmat {
    background: #000 url(../images/banners/hazmat-bg-sm.jpg) no-repeat 50% 50% / cover;
  }
  &.home {
    background: #000 url(../images/banners/banner-bg-sm.jpg) no-repeat 50% 50% / cover;
  }
  &.lead-awareness {
    background: #000 url(../images/banners/lead-awareness-bg-sm.jpg) no-repeat 50% 50% / cover;
  }
  &.manager-refresher {
    background: #000 url(../images/banners/manager-refresher-bg-sm.jpg) no-repeat 50% 50% / cover;
  }
  &.msha {
    background: #000 url(../images/banners/msha-bg.jpg) no-repeat 50% 50% / cover;
  }
  &.new-entrants {
    background: #000 url(../images/banners/new-entrants-bg-sm.jpg) no-repeat 50% 10% / cover;
  }
  &.ny-construction {
    background: #000 url(../images/banners/construction-industry-bg-sm.jpg) no-repeat 50% 50% / cover;
  }
  &.ny-courses {
    background: #000 url(../images/banners/ny-main-bg-sm.jpg) no-repeat 50% 50% / cover;
  }
  &.ny-general {
    background: #000 url(../images/banners/general-industry-bg-sm.jpg) no-repeat 50% 50% / cover;
  }
  &.oeca {
    background: #000 url(../images/banners/oeca-bg.jpg) no-repeat 50% 50% / cover;
  }
  &.about-us {
    background: #000 url(../images/banners/oeca-bg.jpg) no-repeat 50% 50% / cover;
  }
  &.offer1 {
    background-image: linear-gradient(to right, rgba(193, 101, 5, 0.45) 0, rgba(0, 0, 0, 0) 100%),
      url(../images/banners/offer1-bg-sm.jpg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
  &.offer2 {
    background-image: linear-gradient(to right, rgba(193, 101, 5, 0.45) 0, rgba(0, 0, 0, 0) 100%),
      url(../images/banners/offer2-bg-sm.jpg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
  &.offer3 {
    background-image: linear-gradient(to right, rgba(4, 142, 195, 0.53) 0, rgba(0, 0, 0, 0) 100%),
      url(../images/banners/offer3-bg-sm.jpg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
  &.offer4 {
    background-image: linear-gradient(to right, rgba(195, 101, 4, 0.48) 0, rgba(0, 0, 0, 0) 100%),
      url(../images/banners/offer4-bg-sm.jpg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
  &.pretask-safety-meeting {
    background: #000 url(../images/banners/pretask-safety-meeting-bg-sm.jpg) no-repeat 50% 50% / cover;
  }
  &.scaffolds-suspended {
    background: #000 url(../images/banners/scaffolds-suspended-bg-sm.jpg) no-repeat 50% 50% / cover;
  }
  &.site-safety-plan {
    background: #000 url(../images/banners/site-safety-plan-bg-sm.jpg) no-repeat 50% 50% / cover;
  }
  &.spanish-ten-construction-hazrec {
    background: #000 url(../images/banners/10-spanish-construction-hazrec-bg-sm.jpg) no-repeat 50% 50% / cover;
  }
  &.spanish-ten-construction-outreach {
    background: #000 url(../images/banners/10-spanish-construction-training-bg-sm.jpg) no-repeat 50% 50% / cover;
  }
  &.spanish-ten-genindustry-hazrec {
    background: #000 url(../images/banners/10-spanish-genindustry-hazrec-bg-sm.jpg) no-repeat 50% 50% / cover;
  }
  &.spanish-ten-genindustry-outreach {
    background: #000 url(../images/banners/10-spanish-genindustry-training-bg-sm.jpg) no-repeat 50% 50% / cover;
  }
  &.spanish-thirty-construction-hazrec {
    background: #000 url(../images/banners/30-spanish-construction-hazrec-bg-sm.jpg) no-repeat 50% 50% / cover;
  }
  &.spanish-thirty-construction-outreach {
    background: #000 url(../images/banners/30-spanish-construction-training-bg-sm.jpg) no-repeat 50% 50% / cover;
  }
  &.spanish-thirty-genindustry-hazrec {
    background: #000 url(../images/banners/30-spanish-genindustry-hazrec-bg-sm.jpg) no-repeat 50% 50% / cover;
  }
  &.spanish-thirty-genindustry-outreach {
    background: #000 url(../images/banners/30-spanish-genindustry-training-bg-sm.jpg) no-repeat 50% 50% / cover;
  }
  &.sst {
    background: #000 url(../images/banners/sst-bg-sm.jpg) no-repeat 50% 50% / cover;
  }
  &.sst-card {
    background: #000 url(../images/banners/sst-card-bg-sm.jpg) no-repeat 50% 50% / cover;
  }
  &.sst-law {
    background: #000 url(../images/banners/sst-info-bg-sm.jpg) no-repeat 50% 50% / cover;
  }
  &.stairways-ladders {
    background: #000 url(../images/banners/stairways-and-ladders-bg-sm.jpg) no-repeat 50% 50% / cover;
  }
  &.supervisors {
    background: #000 url(../images/banners/supervisors-bg-sm.jpg) no-repeat 50% 10% / cover;
  }
  &.supported-scaffold {
    background: #000 url(../images/banners/supported-scaffold-bg-sm.jpg) no-repeat 50% 50% / cover;
  }
  &.ten {
    background: #000 url(../images/banners/hour-10-bg-sm.jpg) no-repeat 50% 25% / cover;
  }
  &.ten-construction-international {
    background: #000 url(../images/banners/10-hour-construction-international-bg-sm.jpg) no-repeat 50% 50% / cover;
  }
  &.ten-construction-short {
    background: #000 url(../images/banners/construction-10-bg-sm.jpg) no-repeat 50% 50% / cover;
  }
  &.ten-general-short {
    background: #000 url(../images/banners/general-industry-10-bg-sm.jpg) no-repeat 50% 50% / cover;
  }
  &.ten-international {
    background: #000 url(../images/banners/10-hour-international-bg-sm.jpg) no-repeat 50% 50% / cover;
  }
  &.thirty {
    background: #000 url(../images/banners/hour-30-bg-sm.jpg) no-repeat 50% 25% / cover;
  }
  &.thirty-construction-short {
    background: #000 url(../images/banners/construction-30-bg-sm.jpg) no-repeat 50% 50% / cover;
  }
  &.thirty-general-short {
    background: #000 url(../images/banners/general-industry-30-bg-sm.jpg) no-repeat 50% 50% / cover;
  }
  &.toolbox-talks {
    background: #000 url(../images/banners/toolbox-talks-bg-sm.jpg) no-repeat 50% 50% / cover;
  }
  &.tools-power {
    background: #000 url(../images/banners/tools-power-bg-sm.jpg) no-repeat 50% 50% / cover;
  }
  &.welding-cutting {
    background: #000 url(../images/banners/welding-cutting-bg-sm.jpg) no-repeat 50% 50% / cover;
  }
  &.workers {
    background: #000 url(../images/banners/workers-bg-sm.jpg) no-repeat 50% 10% / cover;
  }
  &.train-to-work {
    background: #000 url(../images/banners/train-to-work-bg-sm.jpg) no-repeat 50% 50% / cover;
  }
  &.pallet-jack {
    background: #000 url(../images/banners/pallet-jack-bg-sm.jpg) no-repeat 50% 90% / cover;
  }
  &.telehandler {
    background: #000 url(../images/banners/telehandler-bg-sm.jpg) no-repeat 50% 50% / cover;
  }
}
a.img-hover {
  img:hover {
    opacity: 0.8;
  }
}
.dropdown-list {
  width: 100%;
  max-width: 300px;
  span.dropdown.cta-dropdown {
    padding: 16px;
    padding-bottom: 14px;
    color: #fff;
    font-weight: 400;
    background: #ff6431;
    border: 0;
    width: 100%;
    height: auto;
    line-height: 1;
    font-size: 22px;
    text-align: left;
    font-family: 'Fjalla One', 'Open Sans', 'Trebuchet MS', Verdana, Arial, Helvetica, sans-serif;
    text-transform: uppercase;
    &.small {
      padding: 9px 15px 7px;
      font-size: 18px;
      line-height: 1.35;
      & + .drop {
        top: 40px;
      }
    }
    & + .drop {
      top: 50px;
    }
  }
  span.dropdown.rounded-pill {
    background: #fff;
    border-width: 2px;
    border-color: #0872b9;
    color: #0872b9;
    transition: 0.3s;
    &:hover {
      background: #0872b9;
      color: #fff;
      box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
    }
    & + .drop {
      width: 266px;
      left: 17px;
    }
  }
}
#welcome-banner {
  display: none;
  padding: 8px 15px;
  font-weight: 600;
  color: #555;
  border-top: 1px solid #aaa;
  border-bottom: 1px solid #aaa;
  font-size: 1.1em;
  p {
    margin-bottom: 0;
    color: #fc4a1a;
  }
}
.inner-action {
  position: relative;
  min-width: 180px;
  z-index: 1;
  .cta-btn {
    margin-top: -35px;
  }
  .iacet img {
    max-width: 150px;
  }
}
#courses-wrap {
  background-color: #f5f6f7;
  .card.course-display {
    border: 0;
    background: #fff url(../images/section_bg.jpg) top right no-repeat;
    transition: box-shadow 0.3s ease 0s;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
    &:hover {
      box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
    }
    .cta-btn {
      min-width: 220px;
    }
  }
}
#icons-wrap {
  .icon-item {
    border-bottom: 2px dotted #ddd;
    &:last-child {
      border-bottom: 0;
    }
  }
}
.card {
  &.course-display {
    transition: box-shadow 0.3s ease 0s;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
    &:hover {
      box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
    }
  }
  &.reviews {
    color: #fff;
    a {
      color: #fff;
    }
    p.review {
      font-size: 1.2em;
      font-style: italic;
    }
    img {
      &.award {
        max-width: 300px;
        width: 100%;
        height: auto;
      }
    }
  }
}
#callout-wrap {
  p a {
    color: #b4e0f6;
  }
}
#video-wrap {
  background: #fafafa;
  a {
    display: inline-block;
    color: #666;
    border: 0;
    position: relative;
    span {
      display: inline-block;
      font-family: 'Fjalla One', 'Trebuchet MS', Tahoma, Verdana, Helvetica, Arial, sans-serif;
      font-size: 1.2rem;
      line-height: 1.3;
      text-align: left;
      vertical-align: middle;
    }
    &:before {
      display: inline-block;
      margin-right: 10px;
      width: 52px;
      height: 40px;
      content: '';
      background: url(../images/video-icon.png) no-repeat 0 0 / cover;
      vertical-align: middle;
    }
  }
}
blockquote {
  font-size: 1.35rem;
  line-height: 1.2;
  font-weight: 400;
  color: inherit;
  &.headline {
    font-size: 1.75rem;
  }
  p {
    font-family: Georgia, 'Open Sans', 'Trebuchet MS', Verdana, Arial, Helvetica, sans-serif;
    font-style: italic;
    line-height: 1.5;
    &:before,
    &:after {
      margin-right: 5px;
      display: inline-block;
      content: '\201C';
      color: inherit;
      opacity: 0.5;
      font-family: serif;
      font-size: 32px;
      font-weight: 700;
      vertical-align: middle;
      font-style: normal;
      line-height: initial;
      height: 20px;
      margin-top: -13px;
    }
    &:after {
      margin-left: 5px;
      content: '\201D';
    }
  }
  a {
    color: inherit;
  }
}
#reviews-wrap {
  background: #fafafa;
  blockquote {
    color: #777;
  }
  p.more-reviews {
    font-family: 'Fjalla One', 'Open Sans', 'Trebuchet MS', Verdana, Arial, Helvetica, sans-serif;
  }
}
.review blockquote {
  font-family: 'Georgia', 'Verdana', 'Arial', 'Helvetica', 'sans-serif';
  font-size: 1em;
}
.about-card {
  .about-item {
    border-bottom: 1px dotted #aaa;
  }
  .col-lg-6:last-child {
    .about-item {
      border-bottom: 0;
    }
  }
}
aside {
  .dropdown-list {
    max-width: 100%;
  }
}
#course-list {
  .col-check {
    width: auto;
    padding-left: 15px;
    padding-right: 15px;
  }
  .sub-header {
    background-color: #ddd;
    .col-check {
      width: 150px;
    }
  }
  .col-2 {
    min-width: 35px;
  }
  .regular-price.slashprice {
    font-size: 1em;
  }
}
label.custom-checkbox {
  &.small {
    height: 25px;
    width: 25px;
    input[type='checkbox'] {
      & ~ span:before {
        width: 25px;
        height: 25px;
        top: -2px;
      }
      &:checked ~ span:before {
        background-size: 15px 15px;
      }
    }
  }
  margin: 0;
  display: inline-block;
  height: 32px;
  width: 32px;
  cursor: pointer;
  position: relative;
  vertical-align: middle;
  input[type='checkbox'] {
    display: inline-block;
    width: 1px;
    height: 1px;
    position: absolute;
    opacity: 0;
    z-index: -1;
    top: 3px;
    left: 3px;
    & ~ span:before {
      display: block;
      width: 32px;
      height: 32px;
      position: absolute;
      left: 0;
      top: 1px;
      border: 2px solid #ccc;
      content: '';
      border-radius: 3px;
      background-color: #fff;
      background-size: 5px 5px;
      background-position: center center;
      transition: all 0.2s ease 0s;
    }
    &:checked ~ span:before {
      border: 2px solid #2b99ff;
      background-position: center center;
      background-size: 20px 20px;
      background-repeat: no-repeat;
      background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDIxLjAuMiwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMjQgMjQ7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPHN0eWxlIHR5cGU9InRleHQvY3NzIj4KCS5zdDB7ZmlsbDojMkI5OUZGO30KPC9zdHlsZT4KPHBhdGggY2xhc3M9InN0MCIgZD0iTTE5LjEsMy4zbC05LjcsMTBMNC45LDkuMmwtMy43LDMuN2w4LjEsNy44TDIyLjgsN0wxOS4xLDMuM3oiLz4KPC9zdmc+);
      transition: all 0.2s ease 0s;
    }
  }
}
.accordion-wrap {
  .accordion-toggle.icon {
    // accordions with icon in the heading to center arrow
    position: relative;
    &:before {
      position: absolute;
      right: 15px;
      top: 50%;
      transform: translateY(-50%);
      margin-right: 0;
    }
  }
}

.description-accordion {
  .accordion-toggle {
    display: inline-block;
    vertical-align: middle;
    margin-left: 0;
    margin-top: 0;
    margin-bottom: 0;
    padding-right: calc(1em + 10px);
    cursor: pointer;
    // color: inherit;
    overflow: hidden; // for some reason adding this fixes a issue with the icon display
    &:before {
      float: right;
      color: inherit;
      content: '\e817';
      font-family: 'icons';
      font-size: 1.1em;
      font-weight: normal !important;
      height: inherit;
      width: 1em;
      margin-right: -1em;
      text-align: right;
    }
    &:hover {
      color: $link-color;
    }
  }
  &.is-open > .accordion-content {
    height: auto;
    opacity: 1;
    transform: scaleY(1);
    transition:
      transform 0.1s ease-in-out,
      height 0.2s ease-in-out,
      opacity 0.2s ease-in-out;
  }
  &.is-open > .accordion-toggle {
    &:before {
      content: '\e816';
    }
  }
  .accordion-content {
    padding: 0;
    height: 0;
    opacity: 0;
    transform: scaleY(0);
    transform-origin: top;
    transition:
      transform 0.2s ease-in-out,
      height 0.3s cubic-bezier(0, 0.6, 0, 1),
      opacity 0.3s cubic-bezier(0, 0.6, 0, 1);
    overflow: hidden;
  }
}
#offer-modal {
  padding-right: 0 !important;
}
#offer-modal .modal-content {
  background: #ffc48f;
  color: #000;
}
#offer-modal .modal-body .btn-close {
  background-color: transparent;
}
#offer-modal p {
  font-size: 1.3em;
  line-height: 1.6em;
}
#offer-modal p span {
  display: block;
  margin-top: 5px;
  font-size: 1.5em;
  color: #e10000;
}

.text-highlight {
  color: #cfefff;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.8);
}
.bg-forklift {
  background: #000 url(../images/banners/forklift-bg-sm.jpg) no-repeat 50% 10% / cover;
}
.bg-train-to-work {
  background: #000 url(../images/banners/banner-bg-sm.jpg) no-repeat 50% 55% / cover;
}
.bg-osha-promo {
  background: #000 url(../images/banners/sst-card-bg-sm.jpg) no-repeat 50% 10% / cover;
}

#state-courses {
  .card-footer {
    padding: 0;
    background-color: transparent;
    border-top: 0;
  }
  .border-top-highlight {
    border-top: 3px solid $theme-primary;
  }
}

.state-box {
  &:hover {
    div {
      transition: 0.3s;
      background-color: #f2f2f2;
      box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
    }
  }
}

#bulk-quantity {
  &.table {
    --bs-table-bg: transparent;
    tr:last-child {
      td {
        border-bottom: 0;
      }
    }
  }
}
.upsell {
  background: #eef8ff;
  .description {
    strong {
      cursor: pointer;
    }
  }
}
#select-course {
  &:focus {
    width: auto\9;
  }
  option {
    max-width: 200px;
  }
}

#mobile-cart {
  right: 75px;
  bottom: 5px;
  font-size: 22px;
  .cart:before {
    display: inline-block;
    content: '\e8cc';
    font-family: 'Material Icons Outlined';
    vertical-align: middle;
  }
  .cart-text {
    font-size: 0.8em;
  }
}

/* ABC OSHA PAGE */
#abc-icon-wrap {
  .icon {
    h2 {
      &:before {
        display: block;
        margin: 0 auto 10px auto;
        width: 120px;
        height: 120px;
        content: '';
        background: transparent url('../images/icons/feature_icons.png') no-repeat 0 0;
        background-size: cover;
      }
      &.feature-2:before {
        background-position: -120px 0;
      }
      &.feature-3:before {
        background-position: -240px 0;
      }
    }
  }
}

#pill-tab {
  .nav-item {
    .nav-link {
      background: #fff;
      border-width: 2px;
      border-color: #0872b9;
      color: #0872b9;
      transition: 0.3s;
      width: 220px;
      &:hover,
      &.active {
        background: #0872b9;
        color: #fff;
        box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
      }
    }
  }
}

.overlay-wrapper {
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  .overlay {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    transition: 0.3s;
  }
  .banner {
    bottom: 50%;
    left: 50%;
    transform: translate(-50%, 50%);
  }
  &:hover {
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.4);
    .overlay {
      background-color: rgba(0, 0, 0, 0.6);
    }
  }
}

/* ----------------------------------------------------------------------------
    Page Media Queries
----------------------------------------------------------------------------- */
.variant-nav {
  display: none !important;
}

/* ----------------------------------------------------------------------------
    Page Media Queries
----------------------------------------------------------------------------- */
// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
  #mobile-cart {
    right: 130px;
  }

  #video-wrap {
    a {
      span {
        font-size: 1.5rem;
      }
    }
  }
  #offer-modal .modal-dialog {
    max-width: 750px;
  }
  .btn-width {
    width: 220px;
  }
  #icons-wrap {
    .icon-item {
      border-bottom: 0;
      border-right: 2px dotted #ddd;
      &:nth-child(2n) {
        border-right: 0;
      }
    }
  }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
  .container-fluid.but-fixed {
    padding-left: 30px;
    padding-right: 30px;
  }
  #header a.logo {
    img {
      display: block;
      float: left;
    }
    span.big {
      display: inline-block;
      font-size: 1.9em;
    }
    span.small {
      display: inline-block;
    }
  }
  #banner-wrap {
    .price.circle {
      padding: 40px 0 0 0;
      background: #f63;
      border-radius: 100px;
      width: 130px;
      height: 130px;
      .regular-price.slashprice {
        margin-top: 3px;
      }
      &.bigger {
        padding-top: 55px;
        width: 160px;
        height: 160px;
      }
      &.offer {
        padding-top: 30px;
      }
      &.bigger.offer {
        padding-top: 50px;
      }
      &.bigger.offer:has(.slashprice:not(.display-none)) {
        padding-top: 35px;
      }
      span.sale-msg,
      div.sale-msg {
        margin-top: -26px;
      }
    }
  }
  #course-list {
    .col-check {
      width: 120px;
    }
    .sub-header {
      .col-check {
        width: 150px;
      }
    }
  }
  #pill-tab {
    max-width: 700px;
  }
}
// roughtly 900px/16px
@media only screen and (min-width: 56.25em) {
  #footer p.trustmark {
    display: block;
  }
  #header a.logo {
    width: 50%;
    span.small {
      font-size: 18px;
    }
  }
  #header-wrap {
    border-top: 3px solid $theme-primary;
    #header #support {
      display: block;
      span.phone {
        display: inline-block;
      }
    }
  }
  #mobile-cart {
    display: none;
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
  #header a.logo {
    width: 50%;
    span.small {
      font-size: 18px;
    }
  }
  #header #support {
    display: block;
    span.phone {
      display: inline-block;
    }
  }
  #banner-wrap {
    .details {
      padding-left: 40px;
    }
    .transparent {
      margin: 0;
    }
    .action {
      padding-left: 40px;
    }
    .circle.offer {
      background: #f63;
      border-radius: 100px;
      width: 130px;
      height: 130px;
      padding-top: 30px;
      text-align: center;
      text-shadow: none;
      .special-offer-text {
        display: block;
        font-size: 14px;
        line-height: 1;
      }
      .offer {
        display: block;
        margin: 5px 0;
        font-size: 25px;
        font-weight: 700;
        line-height: 1;
      }
      .disclaimer {
        display: block;
        padding: 0 15px;
        font-size: 13px;
        line-height: 1;
      }
    }
    &.abc {
      background: #000 url(../images/banners/abc-bg.jpg) no-repeat 50% 50% / cover;
    }
    &.aerial-scissor-lift {
      background: #000 url(../images/banners/aerial-scissor-lift-bg.jpg) no-repeat 50% 50% / cover;
    }
    &.brand-awareness,
    &.promo-codes {
      background: #000 url(../images/banners/brand-awareness-bg.jpg) no-repeat 50% 50% / cover;
    }
    &.business {
      background: #000 url(../images/banners/business-bg.jpg) no-repeat 50% 50% / cover;
      &.lp {
        background: #000 url(../images/banners/business-bg-lp.jpg) no-repeat 50% 0 / cover;
      }
    }
    &.calosha {
      background: #000 url(../images/banners/calosha-bg.jpg) no-repeat 50% 50% / cover;
    }
    &.calosha-10-con {
      background: #000 url(../images/banners/calosha-10-con-bg.jpg) no-repeat 50% 50% / cover;
    }
    &.calosha-30-con {
      background: #000 url(../images/banners/calosha-30-con-bg.jpg) no-repeat 50% 50% / cover;
    }
    &.calosha-10-gen {
      background: #000 url(../images/banners/calosha-10-gen-bg.jpg) no-repeat 50% 50% / cover;
    }
    &.calosha-30-gen {
      background: #000 url(../images/banners/calosha-30-gen-bg.jpg) no-repeat 50% 50% / cover;
    }
    &.construction-social {
      background: #000 url(../images/banners/oec2-bg.jpg) no-repeat 50% 50% / cover;
    }
    &.concrete-masonry {
      background: #000 url(../images/banners/concrete-and-masonry-bg.jpg) no-repeat 50% 50% / cover;
    }
    &.current-workers {
      background: #000 url(../images/banners/current-workers-bg.jpg) no-repeat 50% 20% / cover;
    }
    &.dot {
      background: #000 url(../images/banners/dot-bg.jpg) no-repeat 50% 50% / cover;
    }
    &.drug-alcohol {
      background: #000 url(../images/banners/da-bg.jpg) no-repeat 50% 50% / cover;
    }
    &.electrocution-prevention {
      background: #000 url(../images/banners/electrocution-prevention-bg.jpg) no-repeat 50% 50% / cover;
    }
    &.excavation {
      background: #000 url(../images/banners/excavations-bg.jpg) no-repeat 50% 50% / cover;
    }
    &.fall-prevention {
      background: #000 url(../images/banners/fall-prevention-bg.jpg) no-repeat 50% 50% / cover;
    }
    &.fire-protection {
      background: #000 url(../images/banners/fire-prevention-bg.jpg) no-repeat 50% 50% / cover;
    }
    &.flagger {
      background: #000 url(../images/banners/flagger-bg.jpg) no-repeat 50% 50% / cover;
    }
    &.forklift {
      background: #000 url(../images/banners/forklift-bg.jpg) no-repeat 50% 30% / cover;
    }
    &.forklift-2 {
      background: #000 url(../images/banners/forklift-2-bg.jpg) no-repeat 50% 30% / cover;
    }
    &.general-social {
      background: #000 url(../images/banners/oec3-bg.jpg) no-repeat 50% 50% / cover;
    }
    &.hazwoper {
      background: #000 url(../images/banners/hazwoper-bg.jpg) no-repeat 50% 50% / cover;
    }
    &.hazmat {
      background: #000 url(../images/banners/hazmat-bg.jpg) no-repeat 50% 50% / cover;
    }
    &.home {
      background: #000 url(../images/banners/banner-bg.jpg) no-repeat 25px 50% / cover;
    }
    &.lead-awareness {
      background: #000 url(../images/banners/lead-awareness-bg.jpg) no-repeat 50% 50% / cover;
    }
    &.manager-refresher {
      background: #000 url(../images/banners/manager-refresher-bg.jpg) no-repeat 50% 50% / cover;
    }
    &.new-entrants {
      background: #000 url(../images/banners/new-entrants-bg.jpg) no-repeat 50% 10% / cover;
    }
    &.ny-construction {
      background: #000 url(../images/banners/construction-industry-bg.jpg) no-repeat 80% 50% / cover;
    }
    &.ny-courses {
      background: #000 url(../images/banners/ny-main-bg.jpg) no-repeat 50% 50% / cover;
    }
    &.ny-general {
      background: #000 url(../images/banners/general-industry-bg.jpg) no-repeat 50% 50% / cover;
    }
    &.offer1 {
      background-image: linear-gradient(to right, rgba(193, 101, 5, 0.45) 0, rgba(0, 0, 0, 0) 100%),
        url(../images/banners/offer1-bg.jpg);
    }
    &.offer2 {
      background-image: linear-gradient(to right, rgba(193, 101, 5, 0.45) 0, rgba(0, 0, 0, 0) 100%),
        url(../images/banners/offer2-bg.jpg);
    }
    &.offer3 {
      background-image: linear-gradient(to right, rgba(4, 142, 195, 0.53) 0, rgba(0, 0, 0, 0) 100%),
        url(../images/banners/offer3-bg.jpg);
    }
    &.offer4 {
      background-image: linear-gradient(to right, rgba(195, 101, 4, 0.48) 0, rgba(0, 0, 0, 0) 100%),
        url(../images/banners/offer4-bg.jpg);
    }
    &.pretask-safety-meeting {
      background: #000 url(../images/banners/pretask-safety-meeting-bg.jpg) no-repeat 50% 50% / cover;
    }
    &.scaffolds-suspended {
      background: #000 url(../images/banners/scaffolds-suspended-bg.jpg) no-repeat 50% 50% / cover;
    }
    &.site-safety-plan {
      background: #000 url(../images/banners/site-safety-plan-bg.jpg) no-repeat 50% 50% / cover;
    }
    &.spanish-ten-construction-hazrec {
      background: #000 url(../images/banners/10-spanish-construction-hazrec-bg.jpg) no-repeat 50% 50% / cover;
    }
    &.spanish-ten-construction-outreach {
      background: #000 url(../images/banners/10-spanish-construction-training-bg.jpg) no-repeat 50% 50% / cover;
    }
    &.spanish-ten-genindustry-hazrec {
      background: #000 url(../images/banners/10-spanish-genindustry-hazrec-bg.jpg) no-repeat 50% 50% / cover;
    }
    &.spanish-ten-genindustry-outreach {
      background: #000 url(../images/banners/10-spanish-genindustry-training-bg.jpg) no-repeat 50% 50% / cover;
    }
    &.spanish-thirty-construction-hazrec {
      background: #000 url(../images/banners/30-spanish-construction-hazrec-bg.jpg) no-repeat 50% 50% / cover;
    }
    &.spanish-thirty-construction-outreach {
      background: #000 url(../images/banners/30-spanish-construction-training-bg.jpg) no-repeat 50% 50% / cover;
    }
    &.spanish-thirty-genindustry-hazrec {
      background: #000 url(../images/banners/30-spanish-genindustry-hazrec-bg.jpg) no-repeat 50% 50% / cover;
    }
    &.spanish-thirty-genindustry-outreach {
      background: #000 url(../images/banners/30-spanish-genindustry-training-bg.jpg) no-repeat 50% 50% / cover;
    }
    &.sst {
      background: #000 url(../images/banners/sst-bg.jpg) no-repeat 0 0 / cover;
    }
    &.sst-card {
      background: #000 url(../images/banners/sst-card-bg.jpg) no-repeat 50% 50% / cover;
    }
    &.sst-law {
      background: #000 url(../images/banners/sst-info-bg.jpg) no-repeat 50% 50% / cover;
    }
    &.stairways-ladders {
      background: #000 url(../images/banners/stairways-and-ladders-bg.jpg) no-repeat 50% 50% / cover;
    }
    &.supervisors {
      background: #000 url(../images/banners/supervisors-bg.jpg) no-repeat 50% 10% / cover;
    }
    &.supported-scaffold {
      background: #000 url(../images/banners/supported-scaffold-bg.jpg) no-repeat 50% 50% / cover;
    }
    &.ten {
      background: #000 url(../images/banners/hour-10-bg.jpg) no-repeat 90% 20% / cover;
    }
    &.ten-construction-short {
      background: #000 url(../images/banners/construction-10-bg.jpg) no-repeat 50% 50% / cover;
    }
    &.ten-construction-international {
      background: #000 url(../images/banners/10-hour-construction-international-bg.jpg) no-repeat 50% 50% / cover;
    }
    &.ten-general-short {
      background: #000 url(../images/banners/general-industry-10-bg.jpg) no-repeat 50% 50% / cover;
    }
    &.ten-international {
      background: #000 url(../images/banners/10-hour-international-bg.jpg) no-repeat 50% 50% / cover;
    }
    &.thirty {
      background: #000 url(../images/banners/hour-30-bg.jpg) no-repeat 50% 25% / cover;
    }
    &.thirty-construction-short {
      background: #000 url(../images/banners/construction-30-bg.jpg) no-repeat 50% 50% / cover;
    }
    &.thirty-general-short {
      background: #000 url(../images/banners/general-industry-30-bg.jpg) no-repeat 50% 50% / cover;
    }
    &.toolbox-talks {
      background: #000 url(../images/banners/toolbox-talks-bg.jpg) no-repeat 50% 50% / cover;
    }
    &.tools-power {
      background: #000 url(../images/banners/tools-power-bg.jpg) no-repeat 50% 50% / cover;
    }
    &.welding-cutting {
      background: #000 url(../images/banners/welding-cutting-bg.jpg) no-repeat 50% 50% / cover;
    }
    &.workers {
      background: #000 url(../images/banners/workers-bg.jpg) no-repeat 50% 20% / cover;
    }
    &.train-to-work {
      background: #000 url(../images/banners/train-to-work-bg.jpg) no-repeat 50% 50% / cover;
    }
    &.pallet-jack {
      background: #000 url(../images/banners/pallet-jack-bg.jpg) no-repeat 50% 95% / cover;
    }
    &.telehandler {
      background: #000 url(../images/banners/telehandler-bg.jpg) no-repeat 50% 50% / cover;
    }
  }
  .inner-banner {
    border: 1px solid #ccc;
    &.certs {
      background: #fff url(../images/banners/training-certificate.jpg) no-repeat 50% 50% / cover;
    }
    &.construction {
      background: #000 url(../images/banners/training-construction.jpg) no-repeat 50% 50% / cover;
      h1,
      h2 {
        color: #fff;
      }
    }
    &.hazwoper {
      background: #000 url(../images/banners/training-hazwoper.jpg) no-repeat 50% 50% / cover;
      h1,
      h2 {
        color: #fff;
      }
    }
    &.standards {
      background: #000 url(../images/banners/training-standards.jpg) no-repeat 50% 50% / cover;
      h1,
      h2 {
        color: #fff;
      }
    }
  }

  .about-card {
    .about-item {
      min-height: 120px;
    }
    .col-lg-6:nth-last-child(2) {
      .about-item {
        border-bottom: 0;
      }
    }
  }
  .card.course-box .course-img {
    width: 60% !important;
  }
  .bg-forklift {
    background: #000 url(../images/banners/forklift-bg.jpg) no-repeat 50% 10% / cover;
  }
  .bg-train-to-work {
    background: #000 url(../images/banners/banner-bg-black.jpg) no-repeat 50% 70% / cover;
  }
  .bg-osha-promo {
    background: #000 url(../images/banners/sst-card-bg.jpg) no-repeat 50% 10% / cover;
  }
  #upsellModal {
    --bs-modal-width: 750px;
  }
  #icons-wrap {
    .icon-item {
      border-right: 2px dotted #ddd !important;
      &:last-child {
        border-right: 0 !important;
      }
    }
  }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
  #banner-wrap {
    .details-contained {
      max-width: 680px;
    }
  }
  #pill-tab {
    max-width: none;
    .nav-item {
      .nav-link {
        width: auto;
      }
    }
  }
}

// Extra extra large devices (extra large desktops, 1400px and up)
@media (min-width: 1400px) {
  .container-fluid.but-fixed {
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    padding-left: calc(var(--bs-gutter-x) * 0.5);
  }
  #banner-wrap {
    &.home {
      background-size: contain;
      background-position: 85% 50%;
    }
  }
}
